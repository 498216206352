import React from "react"
import { GamePageQuery_sanityGame_data as GameData } from "../../__generated__/GamePageQuery"
import CenteredBox from "../../ui/spacings/CenteredBox"
import { RichTextField } from "@web-punks/core"
import { CustomTypography } from "@web-punks/ui-mui"
import CTA from "../../ui/atoms/CTA"
import { makeStyles } from "@material-ui/core"
import DefaultLayout from "../../layouts/DefaultLayout"
import { getGameBackground } from "../../converters/contents"
import { FadeIn } from "../../ui/animations"
import { CenteredText } from "../../ui/spacings/AlignedText"
import CustomContainer from "../../ui/spacings/CustomContainer"

interface Props {
  data: GameData
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
  },
  intro: {
    marginTop: theme.spacing(4),
  },
  cta: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  cover: {
    marginTop: theme.spacing(4),
  },
  container: {
    maxWidth: "1000px",
  },
}))

const GameContainer = ({ data }: Props) => {
  const classes = useStyles()
  return (
    <DefaultLayout background={getGameBackground(data)}>
      <CustomContainer className={classes.container}>
        <CenteredBox>
          <FadeIn>
            <CenteredText>
              <CustomTypography
                variant="h2"
                color="primary"
                textClassName={classes.title}
              >
                {data?.contents?.title}
              </CustomTypography>
              <CustomTypography variant="subtitle1">
                {data?.contents?.subtitle}
              </CustomTypography>
              <img
                className={classes.cover}
                src={data.contents.cover?.asset?.url}
              />
              <RichTextField
                className={classes.intro}
                value={data.contents?._rawIntro}
                variant="subtitle1"
              />
            </CenteredText>
          </FadeIn>
          <FadeIn>
            <CTA
              className={classes.cta}
              label="Andiamo!"
              linkItemId={data?.contents?.start?._id as string}
            />
          </FadeIn>
        </CenteredBox>
      </CustomContainer>
    </DefaultLayout>
  )
}

export default GameContainer
