import React from "react"
import { graphql } from "gatsby"
import { Page } from "@web-punks/core"
import { withSanityPreview } from "@web-punks/contents-sanity"
import { GamePageQuery } from "../__generated__/GamePageQuery"
import GameContainer from "../containers/game"
import GameLayoutDataProvider from "../layouts/GameLayout/data"

interface Props {
  data: GamePageQuery
}

const GamePage = ({ data }: Props) => {
  return (
    <GameLayoutDataProvider>
      <Page id={data.sanityGame?._id ?? undefined}>
        <GameContainer data={data.sanityGame?.data as any} />
      </Page>
    </GameLayoutDataProvider>
  )
}

export default withSanityPreview(GamePage)

export const pageQuery = graphql`
  query GamePageQuery($id: String!) {
    sanityGame(_id: { eq: $id }) {
      _id
      data {
        contents {
          _rawIntro(resolveReferences: { maxDepth: 10 })
          _rawStart(resolveReferences: { maxDepth: 10 })
          title
          subtitle
          cover {
            asset {
              url
            }
          }
          start {
            _id
          }
          startLabel
        }
        layout {
          background {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
